import "bootstrap/js/src/alert.js"
import "bootstrap/js/src/collapse.js"
import "@hotwired/turbo-rails"

class AddFieldsTo extends HTMLElement {
  connectedCallback() {
    this.querySelector("button").addEventListener("click", this.handleClick.bind(this))
  }

  get target() {
    return document.querySelector(this.getAttribute("target"))
  }

  handleClick(ev) {
    let template = this.querySelector("template")
    let instance = document.importNode(template.content, { deep: true });
    let uniqueID = (new Date()).getTime();
    for (let field of instance.querySelectorAll("[name]")) {
      let name = field.getAttribute("name");
      field.setAttribute("name", name.replace("XXX", uniqueID))
    }
    for (let input of instance.querySelectorAll("input")) {
      let id = Math.random().toString();
      let label = input.parentElement.querySelector("label");
      input.setAttribute("id", id);
      label.setAttribute("for", id)
    }
    this.target.appendChild(instance)
    ev.preventDefault();
  }
}

customElements.define("add-fields-to", AddFieldsTo)

